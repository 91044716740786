import { setValuesFromStateInLocalStorage } from './SessionHelper';
import DateTimeHelper from './DateTimeHelper';
import ConstantsHelper from './ConstantsHelper';
import AnI18NextLibHelper from './AnI18NextLibHelper';
import UtilityHelper from './UtilityHelper';
import SystemHelper from './SystemHelper';

import { IDashboardRequest, IObjectHash, ITimestampRange } from '../types';
import { loadingActionCreator, errorActionCreator } from '../store/app/app.slice';
import { setAnchorAllReports } from '../store/ui/ui.slice';
import { IError } from '../store/app/app.types';
import { fetchBackEndId } from '../store/app/app.thunks';
import { setOktaData } from '../store/authentication/authentication.slice';
import { IOktaData } from '../store/authentication/authentication.types';
import { IPatient } from '../store/profile/profile.types';
import { fetchProfile } from '../store/profile/profile.thunks';
import { fetchClinics } from '../store/clinics/clinics.thunks';
import {
    fetchLearningMaterialOp5Progress,
    fetchLearningMaterialResources,
} from '../store/learningMaterial/learningMaterial.thunks';
import {
    fetchDashboard,
    fetchDashboardInsulinUsage,
    fetchDashboardInsulinUsageTrend,
    fetchDashboardSummary,
    fetchDashboardSummaryGlucoseTrend,
} from '../store/dashboard/dashboard.thunks';
import { setDashboardMetaData } from '../store/dashboard/dashboard.slice';
import {
    fetchInsightEventBoluses,
    fetchInsightEventModes,
    fetchInsightEventReadings,
} from '../store/insights/insights.thunks';

export default class UiHelper {
    public static GetOnOffSuffix = (isOn: boolean) => (isOn ? 'On' : 'Off');

    public static IsSiteReadyToGo = (allLoaded: boolean, errors: IObjectHash<IError>, receivedData: boolean) => {
        return (receivedData && allLoaded) || UiHelper.HaveError(errors);
    };

    public static SetLoadingSemaphore = (dispatch: any, loadingDir: number) =>
        dispatch(loadingActionCreator({ loadingDir }));

    public static SignOutArm = async ({
        dispatch,
        contributingEventBeg,
        contributingEventEnd,
        contributingEventId,
        insightId,
        dashboardBeg,
        dashboardEnd,
    }: {
        dispatch: any;
        contributingEventBeg: string;
        contributingEventEnd: string;
        contributingEventId: string;
        insightId: string;
        dashboardBeg: string;
        dashboardEnd: string;
    }) => {
        UiHelper.SetLoadingSemaphore(dispatch, 1);

        dispatch(
            setOktaData({
                access: undefined,
                aud: undefined,
                isUserDefined: undefined,
                expires: undefined,
                id: undefined,
                userName: undefined,
            })
        );

        setValuesFromStateInLocalStorage({
            [ConstantsHelper.IntersessionKeys.contributingEventBeg]: contributingEventBeg,
            [ConstantsHelper.IntersessionKeys.contributingEventEnd]: contributingEventEnd,
            [ConstantsHelper.IntersessionKeys.contributingEventId]: contributingEventId,
            [ConstantsHelper.IntersessionKeys.dashboardBeg]: dashboardBeg,
            [ConstantsHelper.IntersessionKeys.dashboardEnd]: dashboardEnd,
            [ConstantsHelper.IntersessionKeys.insightId]: insightId,
            [ConstantsHelper.IntersessionKeys.signOutCanProceed]: '1',
        });
    };

    public static SaveOktaData = (dispatch: any, oktaData: IOktaData): IOktaData => {
        dispatch(setOktaData({ ...oktaData }));

        return oktaData;
    };

    public static FetchPatient = (dispatch: any, oktaData: IOktaData) => {
        if (oktaData?.isUserDefined) {
            dispatch(fetchProfile({}));
        }
    };

    public static FetchClinics = (dispatch: any, oktaData: IOktaData) => {
        if (oktaData.isUserDefined) {
            dispatch(fetchClinics(null));
        }
    };

    public static GetInsightsDateRange = (
        dispatch: any,
        insightIds: string[],
        dashboardBegCurrent: string,
        dashboardEndCurrent: string,
        dashboardHasReferrer?: boolean,
        dashboardBegNew?: string,
        dashboardEndNew?: string
    ): ITimestampRange => {
        const payload: ITimestampRange = {};
        const beg: string = dashboardBegNew ?? dashboardBegCurrent;
        const end: string = dashboardEndNew ?? dashboardEndCurrent;
        const datesChanged = !(beg === dashboardBegCurrent && end === dashboardEndCurrent);

        if (datesChanged) {
            dispatch(setDashboardMetaData({ dashboardBeg: beg, dashboardEnd: end, dashboardHasReferrer }));

            setValuesFromStateInLocalStorage({
                [ConstantsHelper.IntersessionKeys.dashboardBeg]: dashboardBegCurrent,
                [ConstantsHelper.IntersessionKeys.dashboardEnd]: dashboardEndNew,
                [ConstantsHelper.IntersessionKeys.dashboardHasReferrer]: dashboardHasReferrer,
            });
        }

        if (datesChanged || !UiHelper.IsCurrentInsightLoaded(insightIds) || dashboardHasReferrer) {
            payload.beg = beg;
            payload.end = end;
        }

        return payload;
    };

    public static HaveError = (errors: IObjectHash<IError>, warningIsError = false): boolean => {
        const errorObj = Object.entries(errors ?? {}).find(
            (entry) =>
                (warningIsError && entry[1].level === ConstantsHelper.ErrorLevels.warning) ||
                entry[1].level === ConstantsHelper.ErrorLevels.error
        );

        return errorObj ? true : false;
    };

    public static ClearErrors = (dispatch: any) => {
        dispatch(errorActionCreator({ key: undefined, error: undefined }));
    };

    public static CanFetchData = (profileData: IPatient): boolean => {
        const isPauseItEnabled = SystemHelper.IsPauseItEnabled();

        if (isPauseItEnabled && !(profileData?.eulaAccepted && profileData?.confidentialityAgreementAccepted)) {
            return false;
        }

        return true;
    };

    public static FetchBackEndId = (dispatch: any, activeHttpCalls: IObjectHash<boolean>, profileData: IPatient) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchBackEndId);

        if (UtilityHelper.IsHttpCallAllowed(activeHttpCalls, callKey) && UiHelper.CanFetchData(profileData)) {
            dispatch(fetchBackEndId({}));
        }
    };

    public static FetchDashboard = (
        dispatch: any,
        beg: string,
        end: string,
        activeHttpCalls: IObjectHash<boolean>,
        profileData: IPatient
    ) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboard, [beg, end]);

        if (UtilityHelper.IsHttpCallAllowed(activeHttpCalls, callKey) && UiHelper.CanFetchData(profileData)) {
            dispatch(fetchDashboard({ beg, end, limit: 10000, offset: 0 } as IDashboardRequest));
        }
    };

    public static FetchLearningMaterialOp5Progress = (
        dispatch: any,
        beg: string,
        end: string,
        activeHttpCalls: IObjectHash<boolean>,
        profileData: IPatient
    ) => {
        const callKey = UtilityHelper.MakeHttpCallKey(
            ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialOp5Progress,
            [beg, end]
        );

        if (UtilityHelper.IsHttpCallAllowed(activeHttpCalls, callKey) && UiHelper.CanFetchData(profileData)) {
            dispatch(fetchLearningMaterialOp5Progress({ beg, end } as IDashboardRequest));
        }
    };

    public static FetchLearningMaterialResources = (
        dispatch: any,
        beg: string,
        end: string,
        activeHttpCalls: IObjectHash<boolean>,
        profileData: IPatient
    ) => {
        const callKey = UtilityHelper.MakeHttpCallKey(
            ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialResources,
            [beg, end]
        );

        if (UtilityHelper.IsHttpCallAllowed(activeHttpCalls, callKey) && UiHelper.CanFetchData(profileData)) {
            dispatch(fetchLearningMaterialResources({ beg, end } as IDashboardRequest));
        }
    };

    public static FetchDashboardInsulinUsage = (
        dispatch: any,
        beg: string,
        end: string,
        activeHttpCalls: IObjectHash<boolean>,
        profileData: IPatient
    ) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsage, [
            beg,
            end,
        ]);

        if (UtilityHelper.IsHttpCallAllowed(activeHttpCalls, callKey) && UiHelper.CanFetchData(profileData)) {
            dispatch(fetchDashboardInsulinUsage({ beg, end } as IDashboardRequest));
        }
    };

    public static FetchDashboardInsulinUsageTrend = (
        dispatch: any,
        beg: string,
        end: string,
        activeHttpCalls: IObjectHash<boolean>,
        profileData: IPatient
    ) => {
        const callKey = UtilityHelper.MakeHttpCallKey(
            ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsageTrend,
            [beg, end]
        );

        if (UtilityHelper.IsHttpCallAllowed(activeHttpCalls, callKey) && UiHelper.CanFetchData(profileData)) {
            dispatch(fetchDashboardInsulinUsageTrend({ beg, end } as IDashboardRequest));
        }
    };

    public static FetchDashboardSummary = (
        dispatch: any,
        beg: string,
        end: string,
        activeHttpCalls: IObjectHash<boolean>,
        profileData: IPatient
    ) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummary, [
            beg,
            end,
        ]);

        if (UtilityHelper.IsHttpCallAllowed(activeHttpCalls, callKey) && UiHelper.CanFetchData(profileData)) {
            dispatch(fetchDashboardSummary({ beg, end } as IDashboardRequest));
        }
    };

    public static FetchDashboardSummaryGlucoseTrend = (
        dispatch: any,
        beg: string,
        end: string,
        activeHttpCalls: IObjectHash<boolean>,
        profileData: IPatient
    ) => {
        const callKey = UtilityHelper.MakeHttpCallKey(
            ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummaryGlucoseTrend,
            [beg, end]
        );

        if (UtilityHelper.IsHttpCallAllowed(activeHttpCalls, callKey) && UiHelper.CanFetchData(profileData)) {
            dispatch(fetchDashboardSummaryGlucoseTrend({ beg, end } as IDashboardRequest));
        }
    };

    public static FetchInsightEventReadings = (
        dispatch: any,
        insightId: string,
        eventId: string,
        activeHttpCalls: IObjectHash<boolean>,
        profileData: IPatient
    ) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventReadings, [
            insightId,
            eventId,
        ]);

        if (UtilityHelper.IsHttpCallAllowed(activeHttpCalls, callKey) && UiHelper.CanFetchData(profileData)) {
            dispatch(fetchInsightEventReadings({ insightId, eventId }));
        }
    };

    public static FetchInsightEventBoluses = (
        dispatch: any,
        insightId: string,
        eventId: string,
        activeHttpCalls: IObjectHash<boolean>,
        profileData: IPatient
    ) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventBoluses, [
            insightId,
            eventId,
        ]);

        if (UtilityHelper.IsHttpCallAllowed(activeHttpCalls, callKey) && UiHelper.CanFetchData(profileData)) {
            dispatch(fetchInsightEventBoluses({ insightId, eventId }));
        }
    };

    public static FetchInsightEventModes = (
        dispatch: any,
        insightId: string,
        eventId: string,
        activeHttpCalls: IObjectHash<boolean>,
        profileData: IPatient
    ) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventModes, [
            insightId,
            eventId,
        ]);

        if (UtilityHelper.IsHttpCallAllowed(activeHttpCalls, callKey) && UiHelper.CanFetchData(profileData)) {
            dispatch(fetchInsightEventModes({ insightId, eventId }));
        }
    };

    public static setDashboardConfig = (dispatch: any, beg: string, end: string, anchorAllReports: string) => {
        dispatch(
            setDashboardMetaData({
                dashboardBeg: beg,
                dashboardEnd: end,
                dashboardHasReferrer: true,
            })
        );
        dispatch(setAnchorAllReports({ anchorAllReports }));
    };

    public static CreateErrorMessage = (error: IError): IError => ({
        ...error,
        timeStamp: DateTimeHelper.FormatTimeStamp(undefined, undefined, true, false, true),
    });

    public static CopyToClipBoard = (payload: string) => {
        if (navigator.clipboard?.writeText) {
            navigator.clipboard.writeText(payload);
        }
    };

    public static IsCurrentInsightLoaded = (insightIds: string[]) => insightIds?.length > 0;

    public static GetBannerSpacingClasses = (
        isProd: boolean,
        isSupported: boolean,
        classNoBanners: string,
        classOneBanner: string,
        classTwoBanners: string
    ) => {
        if (!isProd && !isSupported) return classTwoBanners;
        if (!isProd || !isSupported) return classOneBanner;

        return classNoBanners;
    };

    public static Translate = (anI18Nextlib: any, key: string, subs?: any) =>
        AnI18NextLibHelper.Translate(anI18Nextlib, key, subs);
}

import SystemHelper from 'src/helpers/SystemHelper';

export enum AppLocalesCodes {
    Czech = 'cs',
    German = 'de',
    Greek = 'el',
    BritishEnglish = 'en-GB',
    USEnglish = 'en-US',
    Spanish = 'es',
    Croatian = 'hr',
    Polish = 'pl',
    Portuguese = 'pt',
}

export function getAppMarketTranslation() {
    const appMarketTranslation = SystemHelper.GetRuntimeConfig('REACT_APP_MARKET_TRANSLATION_CODE');

    if (!appMarketTranslation) return AppLocalesCodes.USEnglish;

    if ((Object.values(AppLocalesCodes) as string[]).includes(appMarketTranslation)) {
        return appMarketTranslation;
    }

    return AppLocalesCodes.USEnglish;
}

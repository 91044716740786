import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';

import { getAppMarketTranslation } from 'src/helpers/getAppMarketTranslation';

import { sendAzureEvent } from '../helpers/appInsights';
import { RootState } from '../store/store';
import { fetchHcpReport } from '../store/app/app.thunks';
import SystemHelper from '../helpers/SystemHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import BtnPrimary from '../components/common/btnPrimary';
import ConstantsHelper from '../helpers/ConstantsHelper';
import UtilityHelper from '../helpers/UtilityHelper';

export default function Download() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);
    const hcpAppUrl = useSelector((state: RootState) => state.app.hcpAppUrl);
    const endpointPdfUrlPrefix = useSelector((state: RootState) => state.app.endpointPdfUrlPrefix);
    const profileData = useSelector((state: RootState) => state.profile.data);
    const oktaData = useSelector((state: RootState) => state.authentication.oktaData);

    const daysMax = UtilityHelper.ReportDaysAvailableGet(profileData);
    const daySelectionsAll = [
        { value: 7, show: daysMax >= 7 },
        { value: 14, show: daysMax >= 14 },
        { value: 30, show: daysMax >= 30 },
        { value: 60, show: daysMax >= 60 },
        { value: 90, show: daysMax >= 90 },
    ];

    const daySelections = daySelectionsAll.filter((daySelection) => daySelection.show);
    const [radioValue, setRadioValue] = useState(0);
    const [radioValueMax, setRadioMaxValue] = useState(0);

    const downloadReportCb = (newRadioValue: number) => {
        const webUrl = hcpAppUrl;
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const encodeUrlOnReport = encodeURIComponent(
            `/report?days=${newRadioValue}&displayOnPrintOnly=true&timeZone=${timeZone}`
        );
        const base64url = btoa(`${webUrl}?path=${encodeUrlOnReport}`);
        const urlParams = [`url=${base64url}`, `token=${oktaData.access}`, 'loadingIndicatorSelector=.loading-content'];
        const url = `${endpointPdfUrlPrefix}/generate?${urlParams.join('&')}`;

        dispatch(fetchHcpReport({ numDays: newRadioValue }));
        window.open(url, '__HCP_REPORT__', 'noopener,noreferrer');

        sendAzureEvent(ConstantsHelper.analyticsEventNames.DOWNLOAD_PHYSICIAN_REPORT, {
            numberOfDays: `${newRadioValue}`,
        });
    };

    useEffect(() => {
        SystemHelper.AppInsightsEvent(anI18Nextlib, 'Download()', 'Mounted');
    }, [anI18Nextlib]);

    useEffect(() => {
        if (profileData.receivedData && profileData.reportDaysAvailableString) {
            const newValue = daySelections.length > 0 ? daySelections[daySelections.length - 1]?.value : 0;

            setRadioValue(newValue);
            setRadioMaxValue(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData.receivedData, profileData.reportDaysAvailableString]);

    useEffect(() => {
        i18n.changeLanguage(getAppMarketTranslation());
    }, []);

    return (
        <div className={clsx(styleGeneral.body, styleGuide.download)}>
            <div className={clsx(styleGuide.heading)}>{t('download.header')}</div>
            {daySelections.length > 0 ? (
                <div className={clsx(styleGuide.subHeading)}>
                    {t('download.bodyData')}
                    <div className={styleGuide.daySelections}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={radioValueMax}
                                value={radioValue}
                                name="radio-buttons-group"
                                onChange={(event, value) => setRadioValue(Number(value))}
                            >
                                {daySelections.map((daySelection, idx) => (
                                    <FormControlLabel
                                        className={styleGuide.daySelection}
                                        key={`daySelection${idx}`}
                                        data-testid={`daySelection${idx}`}
                                        value={daySelection.value}
                                        control={<Radio />}
                                        label={t('download.days', {
                                            count: daySelection.value,
                                        })}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            ) : (
                <div className={clsx(styleGuide.subHeading)}>
                    {t('download.bodyLessThanMinimumData', {
                        to: daySelectionsAll[daySelectionsAll.length - 1].value,
                    })}
                </div>
            )}
            <div>
                <BtnPrimary
                    caption={t('download.downloadReport')}
                    disabled={daysMax === 0}
                    cbClickFn={() => downloadReportCb(daySelections.length > 0 ? radioValue : daysMax)}
                />
            </div>
        </div>
    );
}

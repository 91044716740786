import { IProfileState } from '../../profile.types';

const updateProfileFulfilled = (state: IProfileState, action: any) => {
    state.data = {
        ...state.data,
        ...action.payload,
    };
};

export default updateProfileFulfilled;

import { StatusCodes } from 'http-status-codes';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { Clinic } from '../../model/clinic';
import { RootState } from '../store';
import { ThunkError } from '../root.types';
import SystemHelper from '../../helpers/SystemHelper';
import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';

export const fetchClinics = createAsyncThunk<Clinic[], any, { rejectValue: ThunkError }>(
    'patient/fetchClinics',
    async (params: any, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/clinics`;
        const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);

        if (response.status !== StatusCodes.OK && response.status !== StatusCodes.NOT_FOUND) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedClinics')} (${
                    response.msg
                })`,
            });
        }

        return response.data?.data?.items ?? [];
    }
);

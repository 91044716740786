import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';

import { IDashboardRequest } from '../../types';
import { RootState } from '../store';
import { ThunkError } from '../root.types';
import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';
import SystemHelper from '../../helpers/SystemHelper';

export const fetchBackEndId = createAsyncThunk<string, any, { rejectValue: ThunkError }>(
    'patient/fetchBackEndId',
    async (params: any, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const queryParams = [''];
        const url = `${appState.endpointPwdUrlPrefix}/?${queryParams.join('&')}`;
        const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true, true);

        if (response.status !== StatusCodes.OK) {
            return rejectWithValue({
                message: `${response.msg}.`,
            });
        }

        return response.data;
    }
);

export const fetchHcpReport = createAsyncThunk<number, IDashboardRequest, { rejectValue: ThunkError }>(
    'patient/fetchHcpReport',
    async (params: any, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;

        if ((params?.numDays ?? -1) < 0) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.invalidResponse')}`,
            });
        }

        return params?.numDays;
    }
);
